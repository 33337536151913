<template>
  <div id="newsletter-form" class="newsletter-form">
    <Container>
      <div v-if="success" class="lg:w-8/12 mx-auto z-1 relative">
        <h2 class="first-letter:uppercase">{{ message }}</h2>
      </div>
      <div v-else class="lg:w-8/12 mx-auto z-1 relative">
        <h2 id="signup" class="newsletter-form__title">Subscribe and stay informed</h2>
        <p v-if="errors.global" class="FieldText__message text-red mt-12">
          Error: {{ errors.global }}
        </p>
        <form class="flex flex-wrap -mx-8" @submit.prevent="submit">
          <FieldText
            class="w-full md:w-3/5 px-8"
            label="First Name"
            type="text"
            required
            :error="errors.first_name"
            @focus="clearError('first_name')"
            @input="firstName = $event"
          />
          <FieldText
            class="w-full md:w-2/5 px-8"
            label="Last Name"
            type="text"
            required
            :error="errors.last_name"
            @focus="clearError('last_name')"
            @input="lastName = $event"
          />
          <FieldText
            class="w-full md:w-4/5 px-8"
            label="Email"
            type="email"
            required
            :error="errors.email"
            @focus="clearError('email')"
            @input="email = $event"
          />
          <FieldText
            class="w-full md:w-1/5 px-8"
            label="State"
            type="select"
            required
            placeholder=""
            :select-options="states"
            :error="errors.state"
            @focus="clearError('state')"
            @input="state = $event"
          />
          <div class="md:flex md:flex-row w-full md:justify-between md:items-start px-8">
            <FieldChoice
              v-model="consent"
              class="w-full"
              choice="I consent to the Privacy policy"
              required
              type="checkbox"
              :error="errors.consent"
              name="consent"
              @select="clearError('consent')"
            />

            <Button type="submit" class="w-full md:w-auto" large>Submit</Button>
          </div>
        </form>
      </div>

      <Parallax class="newsletter-form__shape-tl" />

      <Parallax class="newsletter-form__shape-bl">
        <shape id="Curve1" :themes="['green']"></shape>
      </Parallax>

      <Parallax class="newsletter-form__shape-tr">
        <shape id="Oval2" :themes="['yellow']"></shape>
      </Parallax>
    </Container>
  </div>
</template>

<script>
import axios from 'axios';
import FieldText from '@/components/FieldText.vue';
import FieldChoice from '@/components/FieldChoice.vue';
import Button from '@/components/Button.vue';
import Container from '@/components/Container.vue';
import Parallax from '@/components/Parallax.vue';
import Shape from '@/components/Shape.vue';

export default {
  name: 'NewsletterForm',
  components: {
    FieldText,
    FieldChoice,
    Button,
    Container,
    Parallax,
    Shape,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      state: '',
      states: ['ACT', 'NSW', 'NT', 'QLD', 'TAS', 'SA', 'VIC', 'WA'],
      consent: false,
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        state: '',
        consent: '',
        global: '',
      },
      message: '',
      success: false,
    };
  },
  methods: {
    submit() {
      axios
        .post('/home/subscribe/', {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          state: this.state,
          consent: this.consent,
        })
        .then((response) => {
          this.success = true;
          this.message = response.data.message;
          document.getElementById('newsletter-form').scrollIntoView({
            behavior: 'smooth',
          });
        })
        .catch((error) => {
          // If it's an error from mailchimp
          if (Object.prototype.hasOwnProperty.call(error.response.data.errors, 'title')) {
            this.$set(this.errors, 'global', error.data.errors.title);
          } else {
            // If it's an error from the server
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$set(this.errors, key, error.data.errors[key][0].message);
            });
          }
        });
    },
    /**
     * Clear an error
     *
     * @param {String} key of error to clear
     */
    clearError(error) {
      this.$set(this.errors, error, '');
    },
  },
};
</script>
