<template>
  <div>
    <h5 v-if="title || menuParent" class="link-list__title">
      {{ title || menuParent.title }}
    </h5>

    <ul v-if="!loading && menuData && menuData.length > 0" class="link-list__list">
      <LinkItem v-for="(item, id) in menuData" :key="id" :item="item" :page-id="pageId" />
    </ul>
    <p v-else-if="!loading && menuData && menuData.length === 0" class="link-list__message">
      <em>No child pages</em>
    </p>
    <p v-else class="link-list__message"><em>Loading pages...</em></p>
  </div>
</template>

<script>
import axios from 'axios';
import LinkItem from '@/components/project_page/LinkItem.vue';

export default {
  name: 'LinkList',
  components: {
    LinkItem,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    pageId: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      default: 'children',
      validator: (value) => ['children', 'siblings'].includes(value),
    },
  },
  mounted() {
    axios
      .get(`/api/v2/nav/${this.pageId}/`)
      .then((response) => {
        const currentPage = this.findNested(response.data.items, this.pageId);
        const pageChildren = currentPage && currentPage.children ? currentPage.children : [];
        this.menuData = this.mode === 'children' ? pageChildren : response.data.items;
        this.menuParent = response.data.parent;
        this.loading = false;
      })
      .catch((error) => {
        this.errorMessage = error;
      });
  },
  data() {
    return {
      menuData: [],
      menuParent: null,
      errorMessage: null,
      loading: true,
    };
  },
  methods: {
    findNested(array, id) {
      let found = array.find((node) => node.id === this.pageId);

      if (found) {
        return found;
      }

      array.forEach((item) => {
        if (!found && item.children) {
          found = this.findNested(item.children, id);
        }
      });

      return found;
    },
  },
};
</script>
