<template>
  <p class="listing-empty">
    No results found.
    <br />
    <button v-if="showClear" class="listing-empty__clear" @click="cleared">
      Clear filters
    </button>
  </p>
</template>

<script>
export default {
  name: 'ListingEmpty',
  props: {
    showClear: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cleared() {
      this.$emit('cleared');
    },
  },
};
</script>
