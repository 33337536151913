import Vue from 'vue'
import App from './App.vue'
import Parallax  from '@/components/Parallax.vue';
import Shape from '@/components/Shape.vue';
import Icon  from '@/components/Icon.vue';
import NewsletterForm from '@/components/NewsletterForm.vue';
import ShareButton from '@/components/ShareButton.vue';
import SaveButton from '@/components/SaveButton.vue';
import YesNo from '@/components/YesNo.vue';
import LinkList from '@/components/project_page/LinkList.vue';
import FaqPage from '@/components/faq_page/FaqPage.vue';
import ProjectIndexPage from '@/components/project_page/ProjectIndexPage.vue';
import ArticleIndexPage from '@/components/article_page/ArticleIndexPage.vue';
import ArticleCard from '@/components/article_page/ArticleCard.vue';
import Navigation from '@/components/navigation/Navigation.vue';
import MenuToggle from '@/components/navigation/MenuToggle.vue';
import NavTools from '@/components/navigation/NavTools.vue';
import Overlay from '@/components/Overlay.vue';
import Download from '@/components/Download.vue';
import Accordion from '@/components/faq_page/Accordion.vue';


Vue.config.productionTip = false;

var store = {
  debug: false,
  state: {
    loginUrl: process.env.VUE_APP_LOGIN_URL,
    showMenu: false,
    navHeight: 0,
  },
  setNavHeight (newValue) {
    if (this.debug) console.log('setNavHeight triggered with', newValue)
    this.state.navHeight = newValue
  },
  setShowMenu (newValue) {
    if (this.debug) console.log('setShowMenu triggered with', newValue)
    this.state.showMenu = newValue
  }
}

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  data: store,
  components: {
    App,
    FaqPage,
    ProjectIndexPage,
    ArticleIndexPage,
    ArticleCard,
    Navigation,
    MenuToggle,
    NavTools,
    LinkList,
    Parallax,
    Shape,
    Icon,
    NewsletterForm,
    ShareButton,
    SaveButton,
    YesNo,
    Overlay,
    Download,
    Accordion,
  },
});
