<template>
  <div
    class="field-text"
    :class="{
      'is-full': fullWidth,
    }"
  >
    <label
      class="field-text__label"
      :class="{
        'is-large': large,
        'is-hidden': hideLabel,
        'is-h2': labelStyle === 'h2',
        'is-h3': labelStyle === 'h3',
        'is-h4': labelStyle === 'h4',
      }"
      :for="uid"
    >
      {{ label }}
    </label>

    <p v-if="hint" class="field-text__hint">
      {{ hint }}
    </p>

    <div class="field-text__control">
      <div v-if="type === 'select'" class="field-text__select">
        <select
          :id="uid"
          v-model="selected"
          class="field-text__input"
          :class="modifierClasses"
          @change="updateOption"
        >
          <option disabled value="">{{ placeholder }}</option>
          <option v-for="option in selectOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>

      <component
        :is="componentEl"
        v-else
        :id="uid"
        class="field-text__input"
        :class="modifierClasses"
        :aria-live="ariaLive"
        :role="ariaRole"
        :max="numberMax"
        :min="numberMin"
        :maxlength="maxlength"
        :type="type"
        :placeholder="placeholder"
        :required="required"
        :value.prop="value"
        @input="updateText"
        @focus="focus"
      ></component>
    </div>

    <p
      v-if="success || error"
      class="field-text__message"
      :class="{
        'is-success': success,
        'is-danger': error,
      }"
    >
      {{ success || error }}
    </p>
  </div>
</template>

<script>
import attrSafe from '@/utilities/attrSafe';

export default {
  name: 'FieldText',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: (val) => ['text', 'email', 'select', 'number', 'textarea'].includes(val),
    },
    large: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    success: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    // Only relevant to select type fields
    selectOptions: {
      type: Array,
      default: () => [],
    },
    // Only relevant with number type fields
    numberMin: {
      type: String,
      default: null,
    },
    numberMax: {
      type: String,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    labelStyle: {
      type: String,
      default: 'h3',
    },
  },
  data() {
    return {
      uid: null,
      selected: this.value,
    };
  },
  computed: {
    modifierClasses() {
      return {
        'is-large': this.large,
        'is-success': this.success,
        'is-danger': this.error,
        'is-number': this.type === 'number',
        'is-multiline': this.type === 'textarea',
      };
    },
    ariaLive() {
      return this.type === 'number' ? 'assertive' : 'off';
    },
    ariaRole() {
      return this.type === 'number' ? 'alert' : null;
    },
    componentEl() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.uid = `${attrSafe(this.label)}_${this._uid}`;
  },
  methods: {
    /**
     * Emit the new text value of an input
     *
     * @prop {Sting} event.
     */
    updateText(event) {
      this.$emit('input', event.target.value);
    },
    /**
     * Emit an input event
     */
    updateOption() {
      // Emitting a `change` event with the new value of the `<select>` field, updates all values bound with `v-model`.
      this.$emit('input', this.selected);
    },
    /**
     * Emit a focus event
     */
    focus() {
      this.$emit('focus');
    },
  },
};
</script>
