<template>
  <TransitionExpand>
    <nav v-if="showMenu" class="menu-accordions">
      <Container>
        <NavTools @navigate="hideMenu" />

        <ul class="menu-accordions__primary">
          <li v-for="(item, index) in items" :key="item.id" class="menu-accordions__item">
            <button
              v-if="item.children"
              class="menu-accordions__expand"
              :class="{
                'is-open': openAccordionIndex === index,
              }"
              @click="toggleSubmenu(index)"
            >
              <Icon id="AngleDown" />
            </button>

            <a :href="item.url" class="menu-accordions__link" @click="hideMenu">
              <span
                class="menu-accordions__underline"
                :class="{
                  'is-open': openAccordionIndex === index,
                }"
              >
                {{ item.title }}
              </span>
            </a>

            <TransitionExpand v-if="item.children">
              <ul
                v-show="openAccordionIndex === index"
                class="menu-accordions__secondary"
                :aria-expanded="openAccordionIndex === index"
              >
                <li v-for="child in item.children" :key="child.id" class="menu-accordions__item">
                  <a :href="child.url" class="menu-accordions__link" @click="hideMenu">
                    <span class="menu-accordions__underline">{{ child.title }}</span>
                  </a>
                </li>
              </ul>
            </TransitionExpand>
          </li>
        </ul>
      </Container>
    </nav>
  </TransitionExpand>
</template>

<script>
import NavTools from '@/components/navigation/NavTools.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import Container from '@/components/Container.vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'SiteNav',
  components: {
    NavTools,
    TransitionExpand,
    Container,
    Icon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      openAccordionIndex: null,
    };
  },
  computed: {
    /**
     * Whether menu is shown
     */
    showMenu() {
      return this.$root.state.showMenu;
    },
  },
  methods: {
    /**
     * Show/Hide menu
     */
    toggleMenu() {
      this.$root.setShowMenu(!this.showMenu);

      // Close any open submenus
      this.hideSubmenus();
    },
    /**
     * Show/Hide submenu
     */
    toggleSubmenu(index) {
      if (this.openAccordionIndex !== index) {
        this.openAccordionIndex = index;
      } else {
        this.hideSubmenus();
      }
    },
    /**
     * Hide submenu
     */
    hideMenu() {
      this.$root.setShowMenu(false);
      this.hideSubmenus();
    },
    /**
     * Hide all submenus
     */
    hideSubmenus() {
      this.openAccordionIndex = null;
    },
  },
};
</script>
