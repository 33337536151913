<template>
  <button
    type="button"
    class="save-button"
    :class="activeClass"
    :disabled="!enabled"
    @click.prevent="toggleSave"
  >
    <Icon v-show="active" :id="iconFilled" />
    <Icon v-show="!active" :id="iconOutlined" />
    <span class="sr-only">{{ savedText }} — </span>
    <span class="save-button__count"
      >{{ count }} <span class="sr-only"> {{ variant }}s total</span></span
    >
  </button>
</template>

<script>
import axios from 'axios';
import Icon from '@/components/Icon.vue';

export default {
  name: 'SaveButton',
  components: {
    Icon,
  },
  props: {
    variant: {
      type: String,
      default: 'like',
    },
    contentType: {
      type: String,
      default: 'resource',
    },
    uid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      enabled: true,
      active: false,
      count: 0,
    };
  },
  computed: {
    /**
     * The accessible text to render depending on whether th button is active or not
     */
    savedText() {
      return this.active
        ? `Un-${this.variant} this ${this.contentType}`
        : `${this.variant} this ${this.contentType}`;
    },
    /**
     * The keyword of the icon; heart
     */
     iconName() {
      return 'heart';
    },
    /**
     * Icon outlined name
     */
    iconOutlined() {
      return `${this.iconName}Outlined`;
    },
    /**
     * Icon filled name
     */
    iconFilled() {
      return `${this.iconName}Filled`;
    },
    /**
     * The active class on the button
     */
     activeClass() {
      return this.active ? 'is-active' : null;
    },
    /**
     * The plural of the save type
     */
    variantPlural() {
      return `${this.variant}s`;
    },
  },
  mounted() {
    this.updateCount();
  },
  methods: {
    /**
     * Toggles the UI state
     */
    toggleUI() {
      this.enabled = !this.enabled;
      this.active = !this.active;
    },
    /**
     * Total count for this content
     */
    updateCount() {
      axios
        .get('/feedback/was-it-helpful/pages/')
        .then(({ data }) => {
          const thisContent = data.find(
            (item) => item.page === this.uid,
          );
          this.count = thisContent ? thisContent.count : 0;
        });
    },
    /**
     * Makes a request and saves / unsaves likes or bookmarks
     */
    toggleSave() {
      if (this.enabled) {
        axios
          .post('/feedback/was-it-helpful/create/', { page: this.uid })
          .then(() => {
            this.updateCount();
          })
          .catch(() => {
            this.toggleUI();
          });
        // Optimistically toggle the UI so that the user has immediate UI feedback while we wait for the request to complete
        this.toggleUI();
      }
    },
  },
};
</script>
