<template>
  <ul class="nav-tools" :class="pillClass">
    <li class="nav-tools__item">
      <a href="/search/page/" class="nav-tools__link" @click="navigate">
        <Icon id="SearchSm" aria-hidden="true" class="nav-tools__icon is-pill-icon" />
        <Icon id="SearchMd" aria-hidden="true" class="nav-tools__icon" />
        <span class="nav-tools__text">Search</span>
      </a>
    </li>

    <li class="nav-tools__divider" aria-hidden="true" />

    <li v-if="loginUrl" class="nav-tools__item">
      <a v-if="loginUrl" class="nav-tools__link" :href="loginUrl">
        <Icon id="User" aria-hidden="true" class="nav-tools__icon" />
        <span class="nav-tools__text">Log in</span>
      </a>
    </li>

    <li v-if="loginUrl" class="nav-tools__divider" aria-hidden="true" />

    <li class="nav__tools">
      <a id="recitemeLaunch" href="#" class="nav-tools__link text-black text-semibold">
        <span class="nav-tools__text text-black">Accessibility</span>
      </a>
    </li>
  </ul>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  name: 'NavTools',
  components: {
    Icon,
  },
  props: {
    isPill: {
      type: Boolean,
      default: false,
    },
    showOutline: {
      type: Boolean,
      default: false,
    },
    boldPlaceholder: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  computed: {
    loginUrl() {
      return this.$root.state.loginUrl;
    },
    pillClass() {
      return this.isPill ? 'is-pill' : null;
    },
  },
  methods: {
    /**
     * let parent components know this has been clicked
     */
    navigate() {
      this.$emit('navigate');
    },
  },
};
</script>
