<template>
  <div class="accordion">
    <h4>
      <button
        :id="`${slugHeader}-head`"
        :aria-expanded="expanded.toString()"
        :aria-controls="`${slugHeader}-body`"
        :class="{ 'is-active': expanded }"
        class="accordion__button"
        @click="toggleExpanded"
      >
        <span class="accordion__button-title">{{ header }}</span>

        <span class="accordion__expand">
          <Icon id="AngleDown" />
        </span>
      </button>
    </h4>

    <div
      :id="`${slugHeader}-body`"
      role="region"
      :aria-labelledby="`${slugHeader}-head`"
      :class="{ 'is-active': expanded }"
      class="accordion__body"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import attrSafe from '@/utilities/attrSafe';
import Icon from '@/components/Icon.vue';

export default {
  name: 'Accordion',
  components: {
    Icon,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    slugHeader() {
      return attrSafe(this.header);
    },
  },
  mounted() {
    this.checkHash();
  },
  methods: {
    /**
     * Toggle between expanded and no expanded
     */
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    /**
     * check route for expanded hash
     */
    checkHash() {
      // Handle a hash in the route that points to this accordion, open it and scroll to it
      this.expanded = window.location.hash === `#${this.slugHeader}`;

      if (this.expanded) {
        const headId = `${this.slugHeader}-head`;
        document.getElementById(headId).scrollIntoView();
      }
    },
  },
};
</script>
