<template>
  <div id="navigation" ref="navigation" class="navigation">
    <slot />
    <MenuAccordions :aria-hidden="breakpoint.nav" :items="menuItems" />
    <MenuDropdowns :aria-hidden="!breakpoint.nav" :items="menuItems" />

    <div class="navigation__curve">
      <Shape id="Curve1" :themes="['gray-50']" transform="rotate(180deg)" />
    </div>

    <div class="navigation__oval">
      <Shape id="Oval1" :themes="['gray-50']" class="navigation__oval" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MenuAccordions from '@/components/navigation/MenuAccordions.vue';
import MenuDropdowns from '@/components/navigation/MenuDropdowns.vue';
import Shape from '@/components/Shape.vue';
import breakpoint from '@/utilities/breakpoint';

export default {
  name: 'Navigation',
  components: {
    MenuAccordions,
    MenuDropdowns,
    Shape,
  },
  data() {
    return {
      breakpoint,
      observer: null,
      menuItems: [],
    };
  },
  mounted() {
    this.applyNavHeight();

    this.observer = new ResizeObserver(() => {
      this.applyNavHeight();
    });

    this.observer.observe(document.getElementById('navigation'));

    axios
      .get('/api/v2/nav/')
      .then(({ data }) => {
        this.menuItems = data;
      });
  },
  unmount() {
    this.observer.disconnect();
  },
  methods: {
    applyNavHeight() {
      this.$root.setNavHeight(this.$refs.navigation.clientHeight);
    },
  },
};
</script>
