<template>
  <li class="link-item" :class="dropdownClass">
    <div class="link-item__head">
      <button
        v-if="item.children"
        :id="`${titleSlug}-button`"
        class="link-item__expand"
        :class="dropdownClass"
        :aria-controls="`${titleSlug}-dropdown`"
        :aria-expanded="isActive.toString()"
        @click="toggleDropdown"
      >
        <span class="link-item__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" class="w-10 icon">
              <title>triangle</title>
              <polygon points="7 0 3.5 4 0 0 7 0"></polygon>
          </svg>
        </span>
      </button>

      <a :href="item.url" class="link-item__link" :class="{ 'is-active': currentItem }">
        {{ item.title }}
      </a>
    </div>

    <transition v-if="item.children" name="link-item-fade">
      <ul
        v-show="isActive"
        :id="`${titleSlug}-dropdown`"
        :aria-labelledby="`${titleSlug}-dropdown`"
        class="link-item__dropdown"
      >
        <LinkItem
          v-for="(childItem, id) in item.children"
          :key="id"
          :item="childItem"
          :page-id="pageId"
        />
      </ul>
    </transition>
  </li>
</template>

<script>
import attrSafe from '@/utilities/attrSafe';

export default {
  name: 'LinkItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    currentItem() {
      return this.pageId === this.item.id;
    },
    dropdownClass() {
      return { 'is-active': this.isActive };
    },
    titleSlug() {
      return attrSafe(this.item.title);
    },
  },
  mounted() {
    if (this.item.children) {
      this.isActive = this.checkActiveAncestor(this.item.children);
    }
  },
  methods: {
    toggleDropdown() {
      this.isActive = !this.isActive;
    },
    checkActiveAncestor(arr) {
      const par = arr.some((item) => {
        if (item.id !== this.pageId && item.children) {
          return this.checkActiveAncestor(item.children);
        }
        return item.id === this.pageId;
      });
      return par;
    },
  },
};
</script>
