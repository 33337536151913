<template>
  <Container>
    <div class="filter-list">
      <div class="filter-list__column">
        <label class="filter-list__label">{{ searchLabel }}</label>

        <div class="filter-list__control">
          <Icon id="SearchSm" class="filter-list__input-icon" />
          <input
            v-model="value.search"
            class="filter-list__input"
            type="text"
            placeholder="Search..."
          />
        </div>
      </div>

      <div class="filter-list__column">
        <label class="filter-list__label">{{ selectLabel }}</label>

        <div class="filter-list__control">
          <div v-if="categories.length">
            <select
              v-model="value.category"
              class="filter-list__select"
              placeholder="All categories"
            >
              <option value="">
                All categories
              </option>

              <option v-for="(cat, idx) in categories" :key="idx" :value="cat.id">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="value.category || value.search" class="filter-list__extras">
        <button title="Reset filters" class="filter-list__reset" @click="resetFilters">
          <Icon id="Reset" class="w-10" aria-label="Reset" />
          <span>Reset Criteria</span>
        </button>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from '@/components/Container.vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'FilterList',
  components: {
    Container,
    Icon,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    searchLabel: {
      type: String,
      default: 'Search questions',
    },
    selectLabel: {
      type: String,
      default: 'Search categories',
    },
  },
  methods: {
    resetFilters() {
      this.$emit('input', {
        categories: [],
        search: null,
      });
    },
  },
};
</script>
