<template>
  <button
    class="menu-toggle"
    :class="{ 'is-active': showMenu }"
    title="Menu Toggle"
    @click="toggleMenu"
  >
    <span />
    <span />
    <span />
  </button>
</template>

<script>
export default {
  name: 'MenuToggle',
  computed: {
    showMenu() {
      return this.$root.state.showMenu;
    },
  },
  methods: {
    /**
     * Show/Hide menu by setting the state
     */
    toggleMenu() {
      this.$root.setShowMenu(!this.showMenu);
    },
  },
};
</script>
