<template>
  <div
    ref="parallax"
    class="parallax"
    :style="styleObject"
    :class="{
      'is-relative': relative,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Parallax',
  props: {
    speed: {
      type: Number,
      default: 0.1,
    },
    relative: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleTop: 0,
      shape: null,
      container: null,
    };
  },
  computed: {
    styleObject() {
      return {
        transform: `translate(0, ${this.styleTop}px)`,
      };
    },
  },
  mounted() {
    this.setupParallax();
  },
  beforeDestroy() {
    this.destroyParallax();
  },
  methods: {
    /**
     * Initialise parallax effect.
     * @return false
     */
    setupParallax() {
      this.shape = this.$refs.parallax;
      this.container = this.shape.parentNode;

      this.calcParallax();

      window.addEventListener('scroll', this.tickParallax);

      return false;
    },
    /**
     * Calculate the  parallax once a frame render is called
     */
    tickParallax() {
      requestAnimationFrame(this.calcParallax);
    },
    /**
     * Calculate position for one frame of the parallax effect.
     */
    calcParallax() {
      const scrollTop = window.pageYOffset;
      const winHeight = window.innerHeight;
      const contTop = this.offset(this.container).top;

      // Check if cont is in viewport
      if (scrollTop + winHeight > contTop) {
        const top = (scrollTop - contTop + 100) * this.speed;

        this.styleTop = top;
      }
    },
    /**
     * Cleanup scroll events if element is unmounted
     */
    destroyParallax() {
      window.removeEventListener('scroll', this.tickParallax);
    },
    /**
     * Calculate and return the top and left positionSidebar of an element relative to the page.
     * @return {top: (float), left: (float)}
     */
    offset(el) {
      const rect = el.getBoundingClientRect();
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
  },
};
</script>
