<template lang="html">
  <Container>
    <div class="filter-group">
      <div class="filter-group__controls">
        <h2 class="sr-only">{{ title }}</h2>

        <button
          class="filter-group__toggle"
          :class="{ 'is-expanded': expanded }"
          @click="toggleFilters"
        >
          <span>{{ title }}</span>
          <Icon id="AngleDown" class="filter-group__arrow" />
        </button>

        <div class="filter-group__selected">
          <Button
            v-if="selectedAudience"
            class="filter-group__tag"
            round
            @click.native="clearAudience"
          >
            <Icon id="Cross" class="filter-group__cross" />
            {{ selectedAudience }}
          </Button>

          <Button v-if="selectedIssue" class="filter-group__tag" round @click.native="clearIssue">
            <Icon id="Cross" class="filter-group__cross" />
            {{ selectedIssue }}
          </Button>

          <button
            v-if="selectedAudience || selectedIssue"
            class="filter-group__clear"
            @click="clearFilters"
          >
            Clear all
          </button>
        </div>
      </div>

      <TransitionExpand>
        <div v-show="expanded" class="filter-group__dropdown" :aria-expanded="expanded">
          <div class="filter-group__section">
            <h3 class="filter-group__label">Target audience</h3>
            <ul class="filter-group__list">
              <li v-for="audience in targetAudiences" :key="audience.id" class="filter-group__item">
                <FieldChoice
                  v-model="selectedAudience"
                  name="audiences"
                  type="radio"
                  :choice="audience.name"
                />
              </li>
            </ul>
          </div>

          <div class="filter-group__section">
            <h3 class="filter-group__label">Issues</h3>
            <ul class="filter-group__list">
              <li v-for="issue in issues" :key="issue.id" class="filter-group__item">
                <FieldChoice
                  v-model="selectedIssue"
                  name="issues"
                  type="radio"
                  :choice="issue.name"
                />
              </li>
            </ul>
          </div>
        </div>
      </TransitionExpand>
    </div>
  </Container>
</template>

<script>
import Icon from '@/components/Icon.vue';
import Button from '@/components/Button.vue';
import Container from '@/components/Container.vue';
import FieldChoice from '@/components/FieldChoice.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default {
  name: 'FilterGroup',
  components: {
    FieldChoice,
    Icon,
    Button,
    Container,
    TransitionExpand,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    targetAudiences: {
      type: Array,
      default: () => [],
    },
    issues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedAudience: null,
      selectedIssue: null,
      expanded: false,
    };
  },
  watch: {
    selectedAudience() {
      this.filterUpdated();
    },
    selectedIssue() {
      this.filterUpdated();
    },
  },
  methods: {
    toggleFilters() {
      this.expanded = !this.expanded;
    },
    clearAudience() {
      this.selectedAudience = false;
    },
    clearIssue() {
      this.selectedIssue = false;
    },
    clearFilters() {
      this.clearAudience();
      this.clearIssue();
    },
    filterUpdated() {
      this.$emit('updated', {
        issue: this.selectedIssue,
        audience: this.selectedAudience,
      });
    },
  },
};
</script>
