<template>
  <div class="yes-no">
    <h4 class="title text-black">{{ title }}</h4>

    <div class="yes-no__buttons">
      <button
        id="thumbs-up-button"
        :disabled="state === 'submitted'"
        :title="yesTitle"
        @click="wasHelpful"
      >
        <svg width="20" height="20"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="thumbs-up icon"
            :class="{ 'is-active': selected === 'yes' }"
            title="yesTitle" icon="ThumbsUp">
            <path
                d="M5 18L8.761 18.94C8.92 18.98 9.083 19 9.246 19H13.263C13.6347 19.0002 13.999 18.8968 14.3152 18.7014C14.6314 18.5061 14.8869 18.2265 15.053 17.894L18.553 10.894C18.7054 10.5891 18.7773 10.2503 18.7619 9.90976C18.7465 9.56924 18.6444 9.2383 18.4651 8.94836C18.2859 8.65842 18.0355 8.4191 17.7378 8.25312C17.4401 8.08714 17.1049 8.00001 16.764 8H12V3C12 2.46957 11.7893 1.96086 11.4142 1.58579C11.0391 1.21071 10.5304 1 10 1H9.905C9.405 1 9 1.405 9 1.905C9 2.619 8.789 3.317 8.392 3.911L5 9V18ZM5 18H3C2.46957 18 1.96086 17.7893 1.58579 17.4142C1.21071 17.0391 1 16.5304 1 16V10C1 9.46957 1.21071 8.96086 1.58579 8.58579C1.96086 8.21071 2.46957 8 3 8H5.66654"
                stroke="#66D19E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </path>
        </svg>
      </button>

      <button
        id="thumbs-down-button"
        :disabled="state === 'submitted'"
        :title="noTitle"
        @click="showFeedbackForm"
      >
        <svg width="20" height="20"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="thumbs-down icon"
            :class="{ 'is-active': selected === 'no' }"
            title="noTitle" icon="ThumbsDown">
            <path
                d="M8.00002 12H3.23602C2.89515 12 2.55994 11.9129 2.26221 11.7469C1.96449 11.5809 1.71412 11.3416 1.53489 11.0516C1.35566 10.7617 1.2535 10.4308 1.23812 10.0902C1.22274 9.74972 1.29465 9.41092 1.44702 9.106L4.94702 2.106C5.11307 1.77369 5.36841 1.49419 5.68439 1.29884C6.00037 1.10349 6.36452 1.00001 6.73602 1H10.754C10.9175 1.0001 11.0804 1.02025 11.239 1.06L14.999 2M14.999 2L15 11L11.608 16.088C11.211 16.683 11 17.381 11 18.096C11 18.595 10.595 19 10.095 19H9.99902C9.46858 19 8.95988 18.7893 8.5848 18.4142C8.20973 18.0391 7.99902 17.5304 7.99902 17V12M14.999 2H17C17.5304 2 18.0392 2.21071 18.4142 2.58579C18.7893 2.96086 19 3.46957 19 4V10C19 10.5304 18.7893 11.0391 18.4142 11.4142C18.0392 11.7893 17.5304 12 17 12H14.3333"
                stroke="#E02F3E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </path>
        </svg>
      </button>
    </div>

    <div v-if="state == 'feedback'">
      <form id="experience-feedback-form" @submit.prevent="sendMessage">
        <FieldText
          class="experience-feedback"
          label="We're sorry to hear that - what could we do to improve your experience?"
          type="textarea"
          placeholder="Your feedback is appreciated"
          required
          @input="updateMessage"
        />

        <input type="submit" class="button" value="Submit" />
      </form>
    </div>

    <p v-else-if="state == 'submitted'" class="feedback-message">{{ confirmMessage }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import FieldText from '@/components/FieldText.vue';

export default {
  name: 'YesNo',
  components: {
    FieldText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      state: 'new', // can be 'new', 'submitted' or 'feedback'
      message: '',
      confirmMessage: 'Thanks for the feedback!',
      selected: null, // can be either 'yes' or 'no'
      success: false,
    };
  },
  computed: {
    /**
     * title for the Yes button — shows explanation for disabled state when submitted
     */
    yesTitle() {
      return this.state === 'submitted' ? this.submittedText : 'Yes!';
    },
    /**
     * title for the No button — shows explanation for disabled state when submitted
     */
    noTitle() {
      return this.state === 'submitted' ? this.submittedText : 'No!';
    },
    /**
     * explanation for disabled state when submitted
     */
    submittedText() {
      return this.selected === 'yes'
        ? "'Yes' feedback already submitted"
        : "'No' feedback already submitted";
    },
  },
  methods: {
    /**
     * Send "was helpful" message"
     */
    wasHelpful() {
      this.selected = 'yes';

      if (this.state !== 'submitted') {
        axios
          .post('/feedback/was-it-helpful/create/', { page: this.pageId })
          .then(() => {
            this.state = 'submitted';
          })
          .catch(() => {
            this.selected = null;
            this.confirmMessage =
              'Sorry, your feedback was not successfully submitted. Please try again.';
          });
      }
    },
    /**
     * Reveal feedback from
     */
    showFeedbackForm() {
      this.selected = 'no';
      this.state = 'feedback';
    },
    /**
     * Update message to be send for feedback
     */
    updateMessage(val) {
      this.message = val;
    },
    /**
     * Send feedback message
     */
    sendMessage() {
      axios
        .post('/feedback/was-it-helpful/send-message/', {
          page_id: this.pageId,
          message: this.message,
        })
        .then(() => {
          this.state = 'submitted';
        })
        .catch(() => {
          this.confirmMessage =
            'Sorry, your feedback was not successfully submitted. Please try again.';
        });
    },
  },
};

// regency
</script>
