<template>
  <a :href="url" class="article-card">
    <div class="article-card__body">
      <div class="article-card__content">
        <h4 class="title text-black">{{ truncateText(title, 60) }}</h4>

        <p class="text-black mb-8">
          {{ truncateText(excerpt, 120) }}
        </p>
        <div class="flex flex-row">
          <SaveButton :uid="id" />
        </div>

      </div>

      <div v-if="thumbnailUrl" class="article-card__image">
        <img :src="thumbnailUrl" :alt="thumbnailAlt" class="w-full" />
      </div>
    </div>
  <TagList :tags="topics" />
  </a>
</template>

<script>
import SaveButton from '@/components/SaveButton.vue';
import TagList from '@/components/TagList.vue';

export default {
  name: 'ArticleCard',
  components: {
    SaveButton,
    TagList,
  },
  props: {
    id: {
      type: Number,
      required: true, 
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    thumbnailAlt: {
      type: String,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
  },
  methods: {
    truncateText(text, maxChars) {
      if (text.length) {
        if (text.length < maxChars) return text;
        const index = maxChars - 3;
        return `${text.substring(0, index)}…`;
      }
      return '';
    },
  },
};
</script>
