<template>
  <transition
    name="transition-expand"
    @enter="start"
    @after-enter="end"
    @before-leave="start"
    @after-leave="end"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionExpand',
  methods: {
    /**
     * Start transition
     */
    start(el) {
      // eslint-disable-next-line
      el.style.height = `${el.scrollHeight}px`;
    },
    /**
     * Wrap up transition at end
     */
    end(el) {
      // eslint-disable-next-line
      el.style.height = '';
    },
  },
};
</script>
