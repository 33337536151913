<template>
  <component :is="buttonType" :href="href" class="button" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Button',
  props: {
    href: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xlarge: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    lowercase: {
      type: Boolean,
      default: false,
    },
    renderAsSpan: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'is-small': this.small,
        'is-large': this.large,
        'is-xlarge': this.xlarge,
        'is-round': this.round,
        'is-inverted': this.invert,
        'is-danger': this.danger,
        'is-lowercase': this.lowercase,
      };
    },
    buttonType() {
      if (this.renderAsSpan) {
        return 'span';
      }
      if (this.href) {
        return 'a';
      }
      return 'button';
    },
  },
};
</script>
