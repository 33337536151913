<template>
  <component :is="href ? 'a' : 'span'" :href="href" class="tag">
    <span class="tag__title" :class="classes">
      <span class="tag__text">{{ title }}</span>
    </span>
  </component>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    title: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'is-dark': this.dark && !this.active,
        'is-large': this.large,
        'is-active': this.active,
      };
    },
  },
};
</script>
