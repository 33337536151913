<template>
  <Container>
    <div class="articles-explorer">
      <div class="articles-explorer__header">
        <h1 class="mb-16">
          {{ pageTitle }}
        </h1>

        <p class="md:text-xl">
          {{ pageExcerpt }}
        </p>
      </div>

      <div class="articles-explorer__filters">
        <form @submit.prevent="searchFromForm">
          <div class="articles-explorer__search-field">
            <input
              v-model="searchTerm"
              type="text"
              placeholder="Search"
              aria-label="Search"
              class="articles-explorer__search-input"
            />

            <Button type="submit" class="articles-explorer__submit" large lowercase round>
              <Icon id="SearchMd" aria-label="Search" class="articles-explorer__button-icon" />
            </Button>
          </div>

          <div class="articles-explorer__topics">
            <h4 class="mb-20">Popular Topics</h4>

            <ul class="flex flex-wrap">
              <li
                v-for="topic in popularTopics"
                :key="topic.id"
                class="articles-explorer__topic"
              >
                <button class="articles-explorer__topic-button" @click="updateTopic(topic.slug)">
                  <Tag
                    :title="topic.name"
                    :active="topic.slug === selectedTopic ? true : false"
                    large
                  />
                </button>
              </li>
            </ul>
          </div>

          <button title="Reset filters" class="articles-explorer__reset" @click="resetFilters">
            <Icon id="Reset" aria-label="Reset" />
            <span>Reset Criteria</span>
          </button>
        </form>
      </div>

      <ul class="articles-explorer__items">
        <li v-for="story in stories" :key="story.id" class="articles-explorer__item">
          <ArticleCard v-bind="story" />
        </li>
      </ul>

      <div class="articles-explorer__footer">
        <p class="title is-h3">Showing {{ itemCount }} of {{ totalCount }}</p>

        <Button v-if="showLoadMore" round xlarge lowercase @click.native="loadMore">
          Load more
        </Button>
      </div>
    </div>
  </Container>
</template>

<script>
import axios from 'axios';
import ArticleCard from '@/components/article_page/ArticleCard.vue';
import Button from '@/components/Button.vue';
import Tag from '@/components/Tag.vue';
import Container from '@/components/Container.vue';
import Icon from '@/components/Icon.vue';

const PAGE_COUNT = 10;
const ORDER = '-last_published_at';

export default {
  name: 'ArticleIndexPage',
  components: { Container, ArticleCard, Button, Tag, Icon },
  props: {
    pageId: {
      type: Number,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
    pageExcerpt: {
      type: String,
      required: true,
    },
    popularTopics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      errorMessage: null,
      stories: [],
      searchTerm: '',
      selectedTopic: '',
      limit: PAGE_COUNT,
      order: ORDER,
      totalCount: 0,
      queryString: '',
      itemCount: 0,
      likes: [],
    };
  },
  computed: {
    showLoadMore() {
      return this.limit < this.totalCount;
    },
  },
  mounted() {
    const searchParams = window.location.search;
    if (searchParams) {
      this.searchFromUrl(searchParams);
    } else {
      this.getRequest();
    }
    this.fetchLikes();
  },
  methods: {
    searchFromForm() {
      /**
       * Update filters from search from. Updates URL Search params and browser history.
       */
      const params = new URLSearchParams();
      if (this.searchTerm) params.set('search', this.searchTerm);
      if (this.selectedTopic) params.set('topic', this.selectedTopic);

      const queryString = params.toString();
      window.history.replaceState({}, '', `?${queryString}`);
      this.queryString = queryString ? `&${queryString}` : '';
      this.getRequest();
    },
    searchFromUrl(query) {
      /**
       * Update search from URL parameters.
       */
      const params = new URLSearchParams(query);
      const queryString = params.toString();
      const searchTerm = params.get('search');
      const topicSlug = params.get('topic');

      this.queryString = queryString ? `&${queryString}` : '';
      this.searchTerm = !searchTerm ? '' : searchTerm;
      this.selectedTopic = !topicSlug ? '' : topicSlug;

      this.getRequest();
    },
    getRequest() {
      const queryUrl = `/api/v2/articles/?child_of=${this.pageId}&order=${this.order}&limit=${this.limit}${this.queryString}`;
      axios
        .get(queryUrl)
        .then((response) => {
          this.stories = response.data.items.map(item => {
            const thumbnail = item.alt_thumbnail || item.thumbnail;

            return {
              ...item,
              thumbnailUrl: thumbnail.url,
              thumbnailAlt: thumbnail.alt
            }
          });
          this.totalCount = response.data.meta.total_count;
          this.itemCount = this.stories.length;
        })
        .catch(() => {
          // Error handling
          this.errorMessage = 'Error fetching articles';
        });
    },
    /**
     * Load next lot of articles
     */
    loadMore() {
      const tempLimit = this.limit + PAGE_COUNT;
      this.limit = tempLimit < this.totalCount ? tempLimit : this.totalCount;
      this.getRequest();
    },
    /**
     * Reset all filters and resubmit search
     */
    resetFilters() {
      this.searchTerm = '';
      this.selectedTopic = '';
      this.getRequest();
    },
    /**
     * Update selected topic
     *
     * @param {String} slug
     */
    updateTopic(slug) {
      this.selectedTopic = this.selectedTopic === slug ? '' : slug;
      this.searchFromForm();
    },
    /**
     * Like count for articles
     */
    fetchLikes() {
      axios
        .get('/feedback/was-it-helpful/pages/')
        .then(({ data }) => {
          this.likes = data;
        })
        .catch(() => {
          this.errorMessage = 'Error fetching likes';
        });
    },
  },
};
</script>
