var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field-text",class:{
    'is-full': _vm.fullWidth,
  }},[_c('label',{staticClass:"field-text__label",class:{
      'is-large': _vm.large,
      'is-hidden': _vm.hideLabel,
      'is-h2': _vm.labelStyle === 'h2',
      'is-h3': _vm.labelStyle === 'h3',
      'is-h4': _vm.labelStyle === 'h4',
    },attrs:{"for":_vm.uid}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.hint)?_c('p',{staticClass:"field-text__hint"},[_vm._v(" "+_vm._s(_vm.hint)+" ")]):_vm._e(),_c('div',{staticClass:"field-text__control"},[(_vm.type === 'select')?_c('div',{staticClass:"field-text__select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"field-text__input",class:_vm.modifierClasses,attrs:{"id":_vm.uid},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.updateOption]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(_vm._s(_vm.placeholder))]),_vm._l((_vm.selectOptions),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2)]):_c(_vm.componentEl,{tag:"component",staticClass:"field-text__input",class:_vm.modifierClasses,attrs:{"id":_vm.uid,"aria-live":_vm.ariaLive,"role":_vm.ariaRole,"max":_vm.numberMax,"min":_vm.numberMin,"maxlength":_vm.maxlength,"type":_vm.type,"placeholder":_vm.placeholder,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":_vm.updateText,"focus":_vm.focus}})],1),(_vm.success || _vm.error)?_c('p',{staticClass:"field-text__message",class:{
      'is-success': _vm.success,
      'is-danger': _vm.error,
    }},[_vm._v(" "+_vm._s(_vm.success || _vm.error)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }