<template>
  <a :href="url" target="_blank" download class="download">
    <div class="download__content">
      <p class="download__title">
        {{ title }}
      </p>
      <p class="download__meta">
        <span>{{ type.toUpperCase() }}</span>
        <span v-if="filesize">{{ filesize.toLowerCase() }}</span>
      </p>
    </div>
    <div class="download__icon">
      <Icon id="Download" />
    </div>
  </a>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  name: 'Download',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'pdf',
    },
    filesize: {
      type: String,
      default: '',
    },
  },
};
</script>
