<template>
  <transition name="fade">
    <div v-if="active" class="overlay" @click="hideOverlay"></div>
  </transition>
</template>

<script>
export default {
  name: 'Overlay',
  computed: {
    active() {
      return this.$root.state.showMenu;
    },
  },
  methods: {
    /**
     * Hide menu
     */
    hideOverlay() {
      this.$root.setShowMenu(false);
    },
  },
};
</script>
