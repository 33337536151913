// Takes any string (even html) and replaces any non-word chains of characters with a single underscore
// Useful for safe use in html id's, or similar
// eg:
// Starting with:
// `I agree to the <a href="#">terms and conditions</a>`
// Becomes:
// `i_agree_to_the_terms_and_conditions`
export default function attrSafe(text) {
  return (
    text
      .toLowerCase()
      // strip html tags
      .replace(/(<([^>]+)>)/gi, '')
      // replace any non-word chars with underscores
      .replace(/\W/g, '_')
      // squash any chains of multiple underscores into a single underscore
      .replace(/__+/g, '_')
      // trim any remaining underscores at the start or end
      .replace(/^_+|_+$/g, '')
  );
}
