<template>
  <div>
    <div class="container">
      <div class="simple-header">
        <h1 class="mb-16">{{ pageTitle }}</h1>
      </div>
    </div>

    <FilterList v-model="filter" :categories="categories" select-label="Relating to:" />

    <Container>
      <Accordions v-if="filteredFaqs.length" :sections="filteredFaqs" />
      <p v-else>No results for current search.</p>
    </Container>
  </div>
</template>

<script>
import axios from 'axios';
import Accordions from '@/components/faq_page/Accordions';
import FilterList from '@/components/faq_page/FilterList';
import Container from '@/components/Container';

export default {
  name: 'Faq',
  components: {
    Accordions,
    Container,
    FilterList,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      categories: [],
      faqs: [],
      filter: {
        category: null,
        search: '',
      },
    };
  },
  mounted() {
    this.fetchCatergories();
    this.fetchFaqs();
  },
  methods: {
    fetchCatergories() {
      axios
      .get('/api/v2/categories/faq-category/')
      .then(({ data }) => {

        this.categories = data;
      });
    },
    fetchFaqs() {
      axios
      .get(`/api/v2/pages/${this.pageId}/`)
      .then(({ data }) => {
        this.faqs = data.faqs;
      });
    }
  },
  computed: {
    filteredFaqs() {
      return this.faqs
      .filter((item) => {
          if (this.filter.category) {
            return parseInt(item.value.category, 10) === this.filter.category;
          }
          return true;
        })
        .filter((item) => {
          if (this.filter.search) {
            const term = this.filter.search.toLowerCase();

            return (
              item.value.answer.toLowerCase().includes(term) ||
              item.value.question.toLowerCase().includes(term)
            );
          }
          return true;
        })
        .map((item) => ({
          id: item.id,
          header: item.value.question,
          body: item.value.answer,
        }));
    },
  },
};
</script>
