<template>
  <p class="save-indicator">
    <Icon :id="iconOutlined" />
    <span class="save-indicator__count"
      >{{ count }}<span class="sr-only"> {{ variant }}s total</span></span
    >
  </p>
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
  name: 'SaveIndicator',
  components: {
    Icon,
  },
  props: {
    variant: {
      type: String,
      default: 'like',
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    /**
     * The keyword of the icon; heart
     */
    iconName() {
      return 'heart';
    },
    /**
     * Icon outlined name
     */
    iconOutlined() {
      return `${this.iconName}Outlined`;
    },
    /**
     * Icon filled name
     */
    iconFilled() {
      return `${this.iconName}Filled`;
    },
  },
};
</script>
