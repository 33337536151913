<template>
  <div class="container" :class="alignClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    align: {
      type: String,
      default: 'normal',
      validator: (alignment) => ['start', 'end', 'center', 'normal'].includes(alignment),
    },
  },
  computed: {
    alignClasses() {
      return {
        'text-center items-center': this.align === 'center',
        'text-left items-start': this.align === 'start',
        'text-right items-end': this.align === 'end',
      };
    },
  },
};
</script>
