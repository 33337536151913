// Simple unility to convert a pixel number to a rem string
const pxToRem = (val) => `${val / 16}rem`;
// Utility to provide rem fontSize and unitless line height from a pixel pair
const fontPair = (fontSize, lineHeight) => [pxToRem(fontSize), lineHeight / fontSize];
// utility to remove 1px from our em widths
const until = (breakpoint) => `${breakpoint - 1 / 16}em`;

const fullConfig = {
  corePlugins: {
    container: false,
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontFamily: {
      body: ['GT Walsheim', 'Helvetica', 'Arial', 'sans-serif'],
      display: ['Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'sans-serif'],
    },
    fontSize: {
      '2xs': fontPair(10, 15),
      xs: fontPair(12, 20),
      sm: fontPair(14, 24),
      base: fontPair(16, 24),
      lg: fontPair(18, 28),
      xl: fontPair(20, 32),
      '2xl': fontPair(24, 32),
      '3xl': fontPair(32, 48),
      '4xl': fontPair(36, 56),
      '5xl': fontPair(48, 72),
    },
    colors: {
      // Colors use 600-900 for shades, 100-400 for tints
      black: '#000',
      white: '#FFF',
      gray: {
        DEFAULT: '#999999',
        25: '#F9F9F9',
        50: '#f2f2f2',
        70: '#f8f8f8',
        100: '#e6e6e6',
        300: '#cccccc',
        600: '#757575',
        700: '#666666',
        800: '#333333',
        900: '#1c1c1c',
      },
      // ADF Blue
      blue: {
        DEFAULT: '#67C6EC',
        200: '#E1F4FB',
        600: '#259de2',
        700: '#036BCC',
        800: '#0471bf',
        900: '#0070C0', // Link blue
      },
      // ADF Yellow
      yellow: {
        DEFAULT: '#FDD65B',
        600: '#f7cf07',
      },
      // ADF Green
      green: {
        DEFAULT: '#66d19e',
        600: '#00ac52',
        700: '#009646',
      },
      // Mint — Active Community Explorer colour
      mint: {
        DEFAULT: '#91f5c8',
        600: '#80deb4',
      },
      red: {
        DEFAULT: '#E02F3E',
        600: '#B32632',
      },
      // Brand colourss
      facebook: '#3b5998',
      twitter: '#1da1f2',
      linkedin: '#0077b5',
      // transparent
      transparent: 'transparent',
      // CurrentColor
      current: 'currentColor',
    },
    spacing: {
      0: '0',
      1: pxToRem(1),
      2: pxToRem(2),
      3: pxToRem(3),
      4: pxToRem(4),
      6: pxToRem(6),
      8: pxToRem(8),
      10: pxToRem(10),
      12: pxToRem(12),
      14: pxToRem(14),
      16: pxToRem(16),
      18: pxToRem(18),
      20: pxToRem(20),
      24: pxToRem(24),
      28: pxToRem(28),
      32: pxToRem(32),
      36: pxToRem(36),
      40: pxToRem(40),
      44: pxToRem(44),
      48: pxToRem(48),
      56: pxToRem(56),
      64: pxToRem(64),
      80: pxToRem(80),
      96: pxToRem(96),
      112: pxToRem(112),
      128: pxToRem(128),
      144: pxToRem(144),
      160: pxToRem(160),
      176: pxToRem(176),
      192: pxToRem(192),
      208: pxToRem(208),
      224: pxToRem(224),
      240: pxToRem(240),
      256: pxToRem(256),
      288: pxToRem(288),
      320: pxToRem(320),
      384: pxToRem(384),
    },
    screens: {
      // typical mobile width
      sm: '36em',
      'until-sm': { max: until(36) },
      // tablet breakpoint starts
      md: '48em',
      'until-md': { max: until(48) },
      // nav breakpoint starts
      nav: '60em',
      'until-nav': { max: until(60) },
      // desktop breakpoint starts
      lg: '64em',
      'until-lg': { max: until(64) },
      print: { raw: 'print' },
    },
    zIndex: {
      // We should never need z-index values other than -1, 0 and 1
      // lest we begin the z-index: 99999 arms race
      '-1': '-1',
      0: 0,
      1: 1,
    },
    extend: {
      borderWidth: {
        4: '4px',
        6: '6px',
        10: '10px',
        12: '12px',
      },
      margin: {
        '0/12': '0',
        '1/12': '8.33333333333%',
        '2/12': '16.66666666666%',
        '3/12': '25%',
        '4/12': '33.33333333333%',
        '5/12': '41.66666666666%',
        '6/12': '50%',
        '7/12': '58.33333333333%',
        '8/12': '66.66666666666%',
        '9/12': '75%',
        '10/12': '83.33333333333%',
        '11/12': '91.66666666666%',
        '-1/12': '-8.33333333333%',
        '-2/12': '-16.66666666666%',
        '-3/12': '-25%',
        '-4/12': '-33.33333333333%',
        '-5/12': '-41.66666666666%',
        '-6/12': '-50%',
        '-7/12': '-58.33333333333%',
        '-8/12': '-66.66666666666%',
        '-9/12': '-75%',
        '-10/12': '-83.33333333333%',
        '-11/12': '-91.66666666666%',
      },
      opacity: {
        99: '0.99',
        10: '0.10',
        3: '0.03',
      },
      maxWidth: {
        16: '1rem',
      },
    },
  },
  variants: {
    margin: ['responsive'],
    padding: ['responsive'],
    width: ['responsive'],
  },
};

const throwError = (msg) => {
  throw new TypeError(
    `Color "${msg}" specified for Shape component does not exist in the Tailwind config`,
  );
};

const getHexFromTailwindConfig = (tailwindColorName) => {
  // Split the color name and variant, and assign to a variables (remembering the existence of defaults)
  const colorValues = tailwindColorName.split('-');
  const colorName = colorValues[0];
  const colorVariant = colorValues.length === 1 ? 'DEFAULT' : colorValues[1];

  // Get our color object, including all variants
  const colorObject = fullConfig.theme.colors[colorName];
  if (!colorObject) throwError(tailwindColorName);

  // Get our hex color
  // Sometimes we will already have our colour as a string, other times this will be an object containing variants
  const hexValue = typeof colorObject === 'string' ? colorObject : colorObject[colorVariant];
  if (!hexValue) throwError(tailwindColorName);
  return hexValue;
};

export default getHexFromTailwindConfig;
