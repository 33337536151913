<template>
  <div class="shape">
    <component :is="id" aria-hidden="true" :style="styleObj" />
  </div>
</template>

<script>
import getHexFromTailwindColor from '@/utilities/getHexFromTailwindColor';
import Vue from 'vue';

export default {
  name: 'Shape',
  components: {
    // Shapes mostly use SVG
    // Basic curve
    Curve1: Vue.component('Curve1',{
        template: '<svg viewBox="0 0 233 143" xmlns="http://www.w3.org/2000/svg"><path d="M0.587952 120.118C0.587952 90.1339 12.2443 60.412 32.6775 38.085C54.4251 14.3217 84.4187 0.597595 117.847 0.597595C151.327 0.597595 180.831 15.311 202.058 40.3236C221.104 62.7655 232.086 92.1343 232.086 120.118C232.086 132.533 222.191 142.596 209.988 142.596C197.914 142.596 188.102 132.748 187.893 120.517L187.89 120.118C187.89 103.041 180.795 84.0664 168.604 69.702C155.512 54.2734 138.029 45.5554 117.847 45.5554C96.8878 45.5554 78.5407 53.9504 65.025 68.7188C52.3345 82.5856 44.9893 101.058 44.7876 119.346L44.7835 120.118C44.7835 132.533 34.89 142.596 22.6857 142.596C10.4815 142.596 0.587952 132.533 0.587952 120.118Z" class="theme-1" /></svg>',
    }),
    // Curve with a dot attched
    CurveDot1: Vue.component('CurveDot1', {
      template: '<svg viewBox="0 0 236 200" xmlns="http://www.w3.org/2000/svg"><path d="M0.587952 120.118C0.587952 90.1339 12.2443 60.412 32.6775 38.085C54.4251 14.3217 84.4187 0.597595 117.847 0.597595C151.327 0.597595 180.831 15.311 202.058 40.3236C221.104 62.7655 232.086 92.1343 232.086 120.118C232.086 132.533 222.191 142.596 209.988 142.596C197.914 142.596 188.102 132.748 187.893 120.517L187.89 120.118C187.89 103.041 180.795 84.0664 168.604 69.702C155.512 54.2734 138.029 45.5554 117.847 45.5554C96.8878 45.5554 78.5407 53.9504 65.025 68.7188C52.3345 82.5856 44.9893 101.058 44.7876 119.346L44.7835 120.118C44.7835 132.533 34.89 142.596 22.6857 142.596C10.4815 142.596 0.587952 132.533 0.587952 120.118Z" class="theme-1"/><circle cx="211.302" cy="175.479" r="24" class="theme-2"/></svg>',
    }),
    // Single oval
    Oval1: Vue.component('Oval1', {
      template: '<svg viewBox="0 0 48 143" xmlns="http://www.w3.org/2000/svg"><rect y="0.780212" width="48" height="142" rx="24" class="theme-1"/></svg>',
    }),
    Oval2: Vue.component('Oval2', {
      template: '<svg viewBox="0 0 48 190" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="190" rx="24" class="theme-1"/></svg>',
    }),
    // Oval dot 1
    OvalDot1: Vue.component('OvalDot1', {
      template: '<svg viewBox="0 0 48 327" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="230.4" rx="24" class="theme-1"/><circle cx="24" cy="254.4" r="24" class="theme-1"/><circle cx="24" cy="302.4" r="24" class="theme-1"/></svg>',
    }),
    // Multiple ovals
    Ovals1: Vue.component('Ovals1', {
      template: '<svg viewBox="0 0 96 322" xmlns="http://www.w3.org/2000/svg"><rect y="41.805" width="48" height="280" rx="24" class="theme-1"/><rect x="48" y="0.283051" width="48" height="280" rx="24" class="theme-1"/></svg>',
    }),
    Ovals2: Vue.component('Ovals2', {
      template: '<svg viewBox="0 0 109 282" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="232.32" rx="24" class="theme-1"/><rect x="60.48" y="48.96" width="48" height="232.32" rx="24" class="theme-1"/></svg>',
    }),
    Ovals3: Vue.component('Ovals3', {
      template: '<svg viewBox="0 0 96 291" xmlns="http://www.w3.org/2000/svg"><rect y="82.1923" width="48" height="208.615" rx="24" class="theme-1"/><rect x="48" y="0.961533" width="48" height="208.615" rx="24" class="theme-1"/></svg>',
    }),
    // Ovals and dots
    OvalsDots1: Vue.component('OvalsDots1', {
      template: '<svg viewBox="0 0 96 475" xmlns="http://www.w3.org/2000/svg"><rect x="48" width="48" height="231.273" rx="24" class="theme-2"/><rect y="147.273" width="48" height="231.273" rx="24" class="theme-1"/><circle cx="24" cy="402.545" r="24" class="theme-1"/><circle cx="24" cy="450.545" r="24" class="theme-1"/></svg>',
    }),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    themes: {
      /**
       * Array of hex colours
       * eg: ['#666666', '#ffff00']
       */
      type: Array,
      default: () => ['gray-50'],
      // validator: (themes) => {
      //   // Check that every theme is a valid 6-digit hex
      //   return themes.every((hex) => isHex(hex));
      // },
    },
    transform: {
      type: String,
      default: null,
    },
  },
  computed: {
    styleObj() {
      return {
        ...this.transformObj,
        ...this.themeVars,
      };
    },
    transformObj() {
      return this.transform ? { transform: this.transform } : {};
    },
    themeVars() {
      return Object.fromEntries(
        this.themes.map((tailwindColorName, index) => {
          const hexValue = getHexFromTailwindColor(tailwindColorName);
          // Set our hex color to the variable
          return [`--theme-${index + 1}`, hexValue];
        }),
      );
    },
  },
};
</script>
