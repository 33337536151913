<template>
  <fieldset class="field-choice">
    <div class="field-choice__control">
      <legend
        v-if="label"
        class="field-choice__title"
        :class="{
          'is-large': large,
          'is-h3': labelStyle === 'h3',
          'is-h4': labelStyle === 'h4',
        }"
      >
        {{ label }}
      </legend>
      <label
        class="field-choice__label"
        :class="{
          'is-large': large,
          'is-danger': error,
        }"
        :for="choiceObject.id"
      >
        <input
          :id="choiceObject.id"
          v-model="checked"
          :value="choice"
          :name="name"
          class="field-choice__input"
          :class="{
            'is-large': large,
            'is-round': round,
            'is-radio': isRadio,
          }"
          :type="type"
          :required="required"
          @change="updateValue"
        />
        
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="field-choice__icon icon">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4998 6.96004L19.7229 4.18311L8.71871 15.1873L4.27694 10.7455L1.5 13.5225L5.94177 17.9642L5.93856 17.9674L8.7155 20.7444L22.4998 6.96004Z">
            </path>
        </svg>
        <span v-if="!hasDefaultSlot" class="field-choice__text">{{ choiceObject.label }}</span>
        <span v-else class="field-choice__text"><slot /></span>
      </label>
    </div>

    <p
      v-if="success || error"
      class="field-choice__message"
      :class="{
        'is-success': success,
        'is-danger': error,
      }"
    >
      {{ success || error }}
    </p>
  </fieldset>
</template>

<script>
import attrSafe from '@/utilities/attrSafe';

export default {
  name: 'FieldChoice',
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'checkbox',
      validator: (val) => ['checkbox', 'radio'].includes(val),
    },
    large: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    success: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    choice: {
      type: String,
      default: 'I agree to the terms and conditions',
    },
    value: {
      type: [String, Boolean],
      default: null,
    },
    labelStyle: {
      type: String,
      default: 'h3',
    },
  },
  data() {
    return {
      uid: null,
      selected: false,
    };
  },
  computed: {
    modifierClasses() {
      return {
        'is-large': this.large,
        'is-round': this.round,
        'is-success': this.success,
        'is-danger': this.error,
        'is-radio': this.isRadio,
      };
    },
    choiceObject() {
      return {
        label: this.choice,
        id: `${attrSafe(this.choice)}_${this.uid}`,
      };
    },
    isRadio() {
      return this.type === 'radio';
    },
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.selected = val;
      },
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.uid = this._uid;
  },
  methods: {
    updateValue() {
      this.$emit('input', this.selected);
      if (this.selected) {
        this.$emit('select');
      }
    },
  },
};
</script>
