var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"field-choice"},[_c('div',{staticClass:"field-choice__control"},[(_vm.label)?_c('legend',{staticClass:"field-choice__title",class:{
        'is-large': _vm.large,
        'is-h3': _vm.labelStyle === 'h3',
        'is-h4': _vm.labelStyle === 'h4',
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('label',{staticClass:"field-choice__label",class:{
        'is-large': _vm.large,
        'is-danger': _vm.error,
      },attrs:{"for":_vm.choiceObject.id}},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"field-choice__input",class:{
          'is-large': _vm.large,
          'is-round': _vm.round,
          'is-radio': _vm.isRadio,
        },attrs:{"id":_vm.choiceObject.id,"name":_vm.name,"required":_vm.required,"type":"checkbox"},domProps:{"value":_vm.choice,"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,_vm.choice)>-1:(_vm.checked)},on:{"change":[function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.choice,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}},_vm.updateValue]}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"field-choice__input",class:{
          'is-large': _vm.large,
          'is-round': _vm.round,
          'is-radio': _vm.isRadio,
        },attrs:{"id":_vm.choiceObject.id,"name":_vm.name,"required":_vm.required,"type":"radio"},domProps:{"value":_vm.choice,"checked":_vm._q(_vm.checked,_vm.choice)},on:{"change":[function($event){_vm.checked=_vm.choice},_vm.updateValue]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"field-choice__input",class:{
          'is-large': _vm.large,
          'is-round': _vm.round,
          'is-radio': _vm.isRadio,
        },attrs:{"id":_vm.choiceObject.id,"name":_vm.name,"required":_vm.required,"type":_vm.type},domProps:{"value":_vm.choice,"value":(_vm.checked)},on:{"change":_vm.updateValue,"input":function($event){if($event.target.composing)return;_vm.checked=$event.target.value}}}),_c('svg',{staticClass:"field-choice__icon icon",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M22.4998 6.96004L19.7229 4.18311L8.71871 15.1873L4.27694 10.7455L1.5 13.5225L5.94177 17.9642L5.93856 17.9674L8.7155 20.7444L22.4998 6.96004Z"}})]),(!_vm.hasDefaultSlot)?_c('span',{staticClass:"field-choice__text"},[_vm._v(_vm._s(_vm.choiceObject.label))]):_c('span',{staticClass:"field-choice__text"},[_vm._t("default")],2)])]),(_vm.success || _vm.error)?_c('p',{staticClass:"field-choice__message",class:{
      'is-success': _vm.success,
      'is-danger': _vm.error,
    }},[_vm._v(" "+_vm._s(_vm.success || _vm.error)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }