<template>
  <nav class="menu-dropdowns">
    <Container>
      <ul class="menu-dropdowns__primary">
        <li
          v-for="(item, index) in items"
          :key="item.id"
          class="menu-dropdowns__item"
          :class="{
            'has-focus': currentFocusIndex === index,
            'is-hoverable': hoverable,
          }"
          @mouseenter="item.children && initDropdowns()"
          @mouseleave="item.children && forceClose()"
        >
          <a :href="item.url" class="menu-dropdowns__link"
            @focus="item.children && setCurrentFocusIndex(index)"
            @blur="item.children && clearCurrentFocusIndex()"
            @click="forceClose"
          >
            {{ item.title }}
            <Icon v-if="item.children" id="AngleDown" class="menu-dropdowns__icon" />
          </a>

          <ul v-if="item.children" class="menu-dropdowns__secondary">
            <li v-for="child in item.children" :key="child.id" class="menu-dropdowns__item">
              <a :href="child.url" class="menu-dropdowns__link"
                @focus="setCurrentFocusIndex(index)"
                @blur="clearCurrentFocusIndex"
                @click="forceClose"
              >
                {{ child.title }}
            </a>
            </li>
          </ul>
        </li>
      </ul>

      <div class="menu-dropdowns__curve-dot">
        <Shape id="CurveDot1" :themes="['gray-50', 'red']" />
      </div>

      <div class="menu-dropdowns__curve">
        <Shape id="Curve1" :themes="['red']" transform="rotate(-90deg)" />
      </div>
    </Container>
  </nav>
</template>

<script>
import Icon from '@/components/Icon.vue';
import Container from '@/components/Container.vue';
import Shape from '@/components/Shape.vue';

export default {
  name: 'MenuDropdowns',
  components: {
    Icon,
    Container,
    Shape,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentFocusIndex: null,
      blurTimeout: null,
      hoverable: true,
    };
  },
  methods: {
    /**
     * Store the index of the currently focused nav item if provided (else will clear it), which will show dropdown menu (if present)
     *
     * @param {Number} index
     */
    setCurrentFocusIndex(index) {
      const hasFocus = index === 0 || !!index;
      clearTimeout(this.blurTimeout);
      this.currentFocusIndex = index;
      this.hoverable = hasFocus;
      this.$root.setShowMenu(hasFocus);
    },
    /**
     * Clear the currently focused nav item index (on a cancellable timeout, in case another nav item receives focus first)
     */
    clearCurrentFocusIndex() {
      clearTimeout(this.blurTimeout);
      this.blurTimeout = setTimeout(() => {
        this.setCurrentFocusIndex(null);
      }, 1);
    },
    /**
     * Force closing of the dropdown menus and screen overlay
     */
    forceClose(e) {
      if (e) {
        e.target.blur();
      }
      this.$root.setShowMenu(false);
      this.hoverable = false;
    },
    /**
     * Allow hoverability of nav dropdowns and show the screen overlay
     */
    initDropdowns() {
      this.$root.setShowMenu(true);
      this.hoverable = true;
    },
  },
};
</script>
