<template>
  <Container>
    <div class="project-card-list">
      <ul v-if="items.length" class="project-card-list__list">
        <li
          v-for="item in items"
          :id="item.meta.slug"
          :key="item.meta.slug"
          class="project-card-list__item"
          :class="{
            'is-active': activeIndex === item.meta.slug,
          }"
        >
          <ProjectCard
            :title="item.title"
            :page-id="item.id"
            :url="item.children.length ? item.children[0].url : null"
            :children="item.children"
            :intro="item.intro"
            :excerpt="item.brief_intro"
            :related-story="item.related_story"
            :download="item.download"
            :shape-name="item.kit_image_shape"
            :shape-color="item.kit_image_colour"
            :expanded="activeIndex === item.meta.slug"
            :slug="item.meta.slug"
            :like-count="getCount(item)"
            @setActive="setActive(item.meta.slug)"
            @clearActive="clearActive"
          />
        </li>
      </ul>

      <ListingEmpty v-else show-clear @cleared="clearFilters" />
    </div>

    <Parallax class="project-card-list__shape-l">
      <Shape id="Curve1" :themes="['gray-50']" />
    </Parallax>

    <Parallax class="project-card-list__shape-r">
      <Shape id="Curve1" :themes="['gray-50']" transform="rotate(-90deg)" />
    </Parallax>
  </Container>
</template>

<script>
import ProjectCard from '@/components/project_page/ProjectCard.vue';
import ListingEmpty from '@/components/project_page/ListingEmpty.vue';
import Container from '@/components/Container.vue';
import Parallax from '@/components/Parallax.vue';
import Shape from '@/components/Shape.vue';

export default {
  name: 'ProjectCardList',
  components: {
    Container,
    Parallax,
    ProjectCard,
    ListingEmpty,
    Shape,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    likes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  watch: {
    $route() {
      this.updateFromHash();
    },
  },
  computed: {
    /**
     * Get the count of likes for a given item
     */
    getCount() {
      return (item) => this.likes.find((like) => like.page === item.id)?.count || 0;
    },
  },
  mounted() {
    this.updateFromHash();
  },
  methods: {
    /**
     * On mount or route change we need to check for a hash and open the right card
     */
    updateFromHash() {
      if (window.location.hash) {
        const slug = window.location.hash.replace('#', '');
        this.setActive(slug);
      }
    },
    /**
     * Set the active index
     */
    setActive(slug) {
      this.activeIndex = slug;
      if (window.location.hash !== `#${slug}`) {
        window.location.hash = `#${slug}`;
      }
    },
    /**
     * Clear the active index
     */
    clearActive() {
      this.activeIndex = null;
    },
    /**
     * reset filters
     */
    clearFilters() {
      this.$emit('clearFilters');
    },
  },
};
</script>
