<template>
  <div class="accordions">
    <Accordion v-for="section in sections" :key="section.id" :header="section.header">
      <div v-html="section.body" />
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/faq_page/Accordion.vue';

export default {
  name: 'Accordions',
  components: {
    Accordion,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
};
</script>
