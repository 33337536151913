<template>
  <ul class="tag-list" :class="classes">
    <li v-for="tag in tags" :key="tag.name" class="tag-list__item">
      <Tag :title="tag.name" :href="tag.href" :dark="dark" :large="large" />
    </li>
  </ul>
</template>

<script>
import Tag from '@/components/Tag';

export default {
  name: 'TagList',
  components: {
    Tag,
  },
  props: {
    tags: {
      type: Array,
      required: true,
      validator: (tags) => {
        // Check that every item has at least a name
        return tags.every((item) => !!item.name);
      },
    },
    dark: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Classes to use
     * @returns {object}
     */
    classes() {
      return {
        'text-center': this.center,
      };
    },
  },
};
</script>
